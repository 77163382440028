import "./styles/main.scss";

function App() {
	return (
		<div className="App">
			<span id="soon">27/03/2023</span>
		</div>
	);
}

export default App;
